export default {
  items: [
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: "icon-chart",
    },
    {
      name: "Audiences",
      url: "/audiences/closed",
      icon: "icon-people",
    },
    {
      name: "Campaigns",
      url: "/campaigns",
      icon: "icon-speech",
    },
    {
      name: "Coupons",
      url: "/c",
      icon: "icon-tag",
    },
    {
      name: "Settings",
      url: "/admin",
      icon: "icon-settings",
    },
  ],
};
