import "./scss/CircularProgress.css";

import {
  ADMIN_HREF,
  API_LOGS_HREF,
  AUDIENCES_HREF,
  AUDIENCE_COUPONS_HREF,
  AUDIENCE_HREF,
  CAMPAIGNS_PATH_HREF,
  CAMPAIGN_BY_ID_HREF,
  CAMPAIGN_HREF,
  COUPON_PORTAL,
  COUPON_REPORT_HREF,
  COUPON_VERIFY,
  CUSTOM_FIELDS_PATH_HREF,
  DASHBOARD_HREF,
  IMPORT_CONTACTS_PATH_HREF,
  IMPORT_OPT_OUT_PATH_HREF,
  INBOX_HREF,
  INTEGRATIONS_HREF,
  OLD_REPORTS_HREF,
  OPTIN_OPTIONS_HREF,
  OPTOUT_REPORT_HREF,
  ORGANIZATION_HREF,
  PLANS_HREF,
  REPORT_HREF,
  SUPER_ADMIN_HREF,
  SURVEY_HREF,
  SURVEY_REPORT_HREF,
} from "../src/common/constants";

import CircularProgress from "@material-ui/core/CircularProgress";
import DefaultLayout from "./containers/DefaultLayout";
import Loadable from "react-loadable";
import React from "react";
import { useWebSocket } from "./WebSocketContext";

/**
 * New format functions
 * @param { NCF_ENABLED } - sets either old client format (false) or new client format (true)
 */
const NCF_ENABLED = process.env.REACT_APP_NCF_ENABLED === "1";

////////////////////////////////////////////////////////////////////////////////////////

function Loading() {
  return (
    <div className="loading">
      <CircularProgress size={100} color="primary" />
    </div>
  );
}
function WebSocketHookWrapper(Component, props) {
  const webSocket = useWebSocket();
  return <Component webSocket={webSocket} {...props} />;
}
const Admin = Loadable({
  loader: () => import("./views/Administrator"),
  loading: Loading,
});
const Dashboard = NCF_ENABLED
  ? Loadable({
      loader: () => import("./views/Dashboard"),
      loading: Loading,
    })
  : Loadable({
      loader: () => import("./views/DashboardOCF"),
      loading: Loading,
    });

const Audiences = Loadable({
  loader: () => import("./views/Audiences"),
  loading: Loading,
});

const Audience = NCF_ENABLED
  ? Loadable({
      loader: () => import("./views/Audience"),
      loading: Loading,
    })
  : Loadable({
      loader: () => import("./views/AudienceOCF"),
      loading: Loading,
    });

const Inbox = NCF_ENABLED
  ? Loadable({
      loader: () => import("./views/Inbox"),
      loading: Loading,
    })
  : Loadable({
      loader: () => import("./views/InboxOCF"),
      loading: Loading,
    });

const Organization = Loadable({
  loader: () => import("./views/Organization"),
  loading: Loading,
});

const OldReports = Loadable({
  loader: () => import("./views/OldReports"),
  loading: Loading,
});

const Report = Loadable({
  loader: () => import("./views/Report"),
  loading: Loading,
});

const OptOutReport = Loadable({
  loader: () => import("./views/OptOutReport"),
  loading: Loading,
});

const CouponReport = NCF_ENABLED
  ? Loadable({
      loader: () => import("./views/CouponReport"),
      loading: Loading,
    })
  : Loadable({
      loader: () => import("./views/CouponReportOCF"),
      loading: Loading,
    });

const SAdmin = Loadable({
  loader: () => import("./views/AdminSuper"),
  loading: Loading,
});

const Plans = Loadable({
  loader: () => import("./views/Plans"),
  loading: Loading,
});

const Campaigns = Loadable({
  loader: () => import("./views/Campaigns"),
  loading: Loading,
});

// const NewCampaign = Loadable({
//   loader: () => import('./views/NewCampaign'),
//   loading: Loading,
// });

// const OldCampaign = Loadable({
//   loader: () => import('./views/OldCampaign'),
//   loading: Loading,
// });

const Campaign = Loadable({
  loader: () => import("./views/Campaign"),
  loading: Loading,
});

const Survey = Loadable({
  loader: () => import("./views/Survey"),
  loading: Loading,
});

const CustomFields = Loadable({
  loader: () => import("./views/CustomFields"),
  loading: Loading,
});

const CouponsList = Loadable({
  loader: () => import("./views/CouponsList"),
  loading: Loading,
});

const CouponsPortal = NCF_ENABLED
  ? Loadable({
      loader: () => import("./views/CouponsPortal"),
      loading: Loading,
    })
  : Loadable({
      loader: () => import("./views/CouponsPortalOCF"),
      loading: Loading,
    });

const CouponVerify = NCF_ENABLED
  ? Loadable({
      loader: () => import("./components/CouponVerify"),
      loading: Loading,
    })
  : Loadable({
      loader: () => import("./components/CouponVerifyOCF"),
      loading: Loading,
    });

const OptInOptions = Loadable({
  loader: () => import("./views/OptInOptions"),
  loading: Loading,
});

const ImportContacts = Loadable({
  loader: () => import("./components/ImportContacts"),
  loading: Loading,
});

const OptOutImport = Loadable({
  loader: () => import("./components/ImportOptOuts"),
  loading: Loading,
});

const ApiLogs = Loadable({
  loader: () => import("./views/ApiLogs"),
  loading: Loading,
});

const Integrations = Loadable({
  loader: () => import("./views/Integrations"),
  loading: Loading,
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: "/", name: "Home", component: DefaultLayout, exact: true },
  //Real Pages
  { path: ADMIN_HREF, name: "Admin", component: Admin },
  { path: PLANS_HREF, name: "Plans", component: Plans },
  { path: DASHBOARD_HREF, name: "Dashboard", component: Dashboard },
  { path: AUDIENCES_HREF, name: "Audiences", component: Audiences },
  { path: AUDIENCE_COUPONS_HREF, name: "Coupons List", component: CouponsList },
  { path: OPTIN_OPTIONS_HREF, name: "Opt-In Options", component: OptInOptions },
  {
    path: IMPORT_CONTACTS_PATH_HREF,
    name: "Import Contacts",
    component: ImportContacts,
  },
  {
    path: IMPORT_OPT_OUT_PATH_HREF,
    name: "Opt Out Import",
    component: OptOutImport,
  },
  {
    path: AUDIENCE_HREF,
    name: "Audience",
    component: (props) => WebSocketHookWrapper(Audience, props),
  },

  { path: ORGANIZATION_HREF, name: "Organization", component: Organization },
  { path: OLD_REPORTS_HREF, name: "OldReports", component: OldReports },
  { path: SURVEY_REPORT_HREF, name: "Report", component: Report },
  {
    path: REPORT_HREF,
    name: "Report",
    component: (props) => WebSocketHookWrapper(Report, props),
  },
  { path: OPTOUT_REPORT_HREF, name: "Opt-Out Report", component: OptOutReport },
  { path: SURVEY_HREF, name: "Survey", component: Survey },
  { path: SUPER_ADMIN_HREF, name: "Super Admin", component: SAdmin },
  // { path: '/newcampaign', name: 'New Campaign', component: NewCampaign },
  // { path: '/campaignr/:id', name: 'CampaignR', component: CampaignR },
  // { path: '/campaignr', name: 'CampaignR', component: CampaignR },
  { path: CAMPAIGN_BY_ID_HREF, name: "Campaign", component: Campaign },
  { path: CAMPAIGN_HREF, name: "Campaign", component: Campaign },
  {
    path: CAMPAIGNS_PATH_HREF,
    name: "Campaigns",
    component: (props) => WebSocketHookWrapper(Campaigns, props),
  },
  {
    path: CUSTOM_FIELDS_PATH_HREF,
    name: "Custom Fields",
    component: CustomFields,
  },
  { path: COUPON_PORTAL, name: "Coupons", component: CouponsPortal },
  { path: COUPON_VERIFY, name: "Coupons Verify", component: CouponVerify },
  { path: COUPON_REPORT_HREF, name: "Coupon Report", component: CouponReport },
  { path: INBOX_HREF, name: "Inbox", component: Inbox },
  { path: API_LOGS_HREF, name: "Api Logs", component: ApiLogs },
  { path: INTEGRATIONS_HREF, name: "Integrations", component: Integrations },
];

export default routes;
